// 刚刚封装的axios
import { newRequest } from "./request.js";

// 1.获取图片验证码
export function getList(type) {
  return newRequest({
    url: "/api/data/cyDataNew.json",
    method: "get",
    data: {
      ...type,
    },
  });
}
export function addWarningPage(e) {
  return newRequest({
    url: "/api/warn/addWarningConfig.json",
    method: "post",
    data: e,
  });
}
export function getWarningLog(e) {
  return newRequest({
    url: "/api/warn/getWarningLog.json",
    method: "post",
    data: e,
  });
}
export function deleWarningPage(e) {
  return newRequest({
    url: "/api/warn/deleteWarningConfig.json",
    method: "post",
    data: {
      id: e.id,
      account: e.account,
      endDate: e.endDate,
      name: e.name,
      startDate: e.startDate,
      status: e.status,
      type: e.type,
      value: e.value,
    },
  });
}
export function updateWarningPage(e) {
  return newRequest({
    url: "/api/warn/updateWarningConfig.json",
    method: "post",
    data: {
      ...e,
    },
  });
}
export function getWarning(e) {
  return newRequest({
    url: "/api/warn/getWarningConfig.json",
    method: "get",
    data: {
      ...e,
    },
  });
}
export function getMonthDataList(selectYear) {
  return newRequest({
    url: `/api/data/monthData.json?area=旺庄,春城&year=${selectYear}&month=0`,
    method: "get",
    data: {},
  });
}
export function getTypeData(type) {
  return newRequest({
    url: "/api/data/getTypeData.json",
    method: "get",
    data: {
      type,
    },
  });
}
export function getYearData(selectYear) {
  return newRequest({
    url: `/api/data/yearData.json?area=春城,旺庄&year=${selectYear}`,
    method: "get",
    data: {},
  });
}
export function getWxUser() {
  return newRequest({
    url: "/api/warn/getWxUser.json",
    method: "post",

    data: {},
  });
}
export function getAqiInfoDetail() {
  return newRequest({
    url: "/api/data/AQIInfoDetail.json",
    method: "get",

    data: {},
  });
}
// /weidata/getDataList.json
export function getWeiZhanDataList(e) {
  return newRequest({
    url: "/api/weidata/getDataList.json",
    method: "get",

    data: e,
  });
}
export function getWeiZhanDataFile(e) {
  return newRequest({
    url: "/api/weidata/getDataListExport.json",
    method: "get",
    responseType: true,
    data: e,
  });
}

export function fontSize(res) {
  let clientWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  if (!clientWidth) return;
  let fontSize = 100 * (clientWidth / 1920);
  return res * fontSize;
}
export function AQIRatio() {
  return newRequest({
    url: "/api/data/AQIRatio.json",
    method: "get",

    data: {},
  });
}
export function AQIData() {
  return newRequest({
    url: "/api/data/AQIData.json",
    method: "get",

    data: {
      type: "day",
    },
  });
}
export function AQIInfo() {
  return newRequest({
    url: "/api/data/AQIInfo.json",
    method: "get",

    data: {},
  });
}
export function gettianqi() {
  return newRequest({
    url: "/api/data/getWeather.json",
    method: "get",
    data: {},
  });
}
export function login(data) {
  return newRequest({
    url: "/api/user/login.json",
    method: "post",
    data: data,
  });
}
export function getAQIInfoDetailOther() {
  return newRequest({
    url: "/api/data/AQIInfoDetailOther.json",
    method: "get",
    data: {},
  });
}

export function getNowDateTime(timeStr) {
  let now = new Date();
  let year = now.getFullYear(); //得到年份
  let month = (now.getMonth() + 1).toString().padStart(2, "0"); //得到月份
  let day = now.getDate().toString().padStart(2, "0"); //得到日期

  if (timeStr == undefined) {
    return `${year}-${month}-${day}`;
  }
  let hours = now.getHours().toString().padStart(2, "0"); // 得到小时;
  let minutes = now.getMinutes().toString().padStart(2, "0"); // 得到分钟;
  let seconds = now.getSeconds().toString().padStart(2, "0"); // 得到秒;
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getAfterDateTime(timeStr) {
  let now = new Date(timeStr);
  // console.log(now, '一年后时间')
  let year = now.getFullYear() + 1; //得到年份
  let month = (now.getMonth() + 1).toString().padStart(2, "0"); //得到月份
  let day = (now.getDate() - 1).toString().padStart(2, "0"); //得到日期

  if (month == "01" && day == "00") {
    year = now.getFullYear(); //得到年份
    month = "12";
    day = "31";
  } else if (day == "00") {
    year = now.getFullYear() + 1; //得到年份
    month = now.getMonth().toString().padStart(2, "0"); //得到月份;
    if (
      month == "01" ||
      month == "03" ||
      month == "05" ||
      month == "07" ||
      month == "08" ||
      month == "10" ||
      month == "12"
    ) {
      //大月
      day = "31";
    } else if (
      month == "04" ||
      month == "06" ||
      month == "09" ||
      month == "11"
    ) {
      //小月
      day = "30";
    } else if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
      //瑞年
      day = "29";
    } else {
      //平年
      day = "28";
    }
  } else {
    year = now.getFullYear() + 1; //得到年份
    month = (now.getMonth() + 1).toString().padStart(2, "0"); //得到月份
    day = (now.getDate() - 1).toString().padStart(2, "0"); //得到日期
  }
  // console.log(year, month, day, '年月日')

  // if (timeStr == undefined) {
  return `${year}-${month}-${day}`;
  // }
  // let hours = now.getHours().toString().padStart(2, "0") // 得到小时;
  // let minutes = now.getMinutes().toString().padStart(2, "0") // 得到分钟;
  // let seconds = now.getSeconds().toString().padStart(2, "0") // 得到秒;
  // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
